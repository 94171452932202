export const callContractMixin = {
    methods: {
        async callContract(
            contractAddress,
            methodName,
            args,
            callback,
            changeState = false,
            value = null
        ) {
            try {
                let myContract = await tronWeb.contract().at(contractAddress);
                let callSend = changeState ? "send" : "call";

                let meta = {
                    callValue: value || 0,
                    feeLimit: 100 * 1000000
                };

                await myContract[methodName](...args)[callSend](meta)
                    .then(function(res) {
                        callback && callback(res);
                        // if (res) {
                        // } else {
                        //     console.log(res);
                        // }
                    });
            } catch (error) {
                console.log(error);
                this.$vs.notify({
                    title: "Contract Error",
                    text: error,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger",
                    fixed: true
                });

                if (
                    error == "REVERT opcode executed" &&
                    this.$route.name != "migrate"
                ) {
                    setTimeout(() => {
                        this.redirectWithDelay();
                    }, 3000);
                }
            }
        },
        redirectWithDelay() {
            if (this.$route.name !== "page-payment") {
                this.$router.push("/register");
            }
        }
    }
};