<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-register"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="require('@/assets/images/pages/ancestry-login.jpg')"
                alt="login"
                class="mx-auto authorize-logo"
              />
            </div>

            <div
              class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
            >
              <div class="p-8 register-tabs-container">
                <div class="vx-card__title mb-8">
                  <h4>Register</h4>

                  <div class="otherlink-box">
                    <!-- Exchange -->
                    <!-- <router-link
                      :to="{ name: 'exchange' }"
                      tag="h4"
                      class="exchange-link text-primary mb-3"
                      >Exchange
                      <feather-icon icon="ArrowRightIcon"></feather-icon>
                    </router-link> -->

                    <!-- Sync -->
                    <!-- <router-link
                      :to="{ name: 'sync' }"
                      tag="h4"
                      class="sync-link text-primary"
                      >Sync ECS
                      <feather-icon icon="ArrowRightIcon"></feather-icon>
                    </router-link> -->
                  </div>
                </div>

                <TronWebComponent />

                <template v-if="haveLink">
                  <span
                    class="tron-web-message"
                    v-if="getTronAccessStatus === false"
                    >Please Install Tron Wallet From
                    <a :href="tronWebInstallAddress">Here</a>.</span
                  >
                  <span class="tron-web-message" v-if="getLoginStatus === false"
                    >Please Login into your Tron Wallet account!</span
                  >

                  <section v-if="getTronConnectionStatus === true">
                    <div>
                      <div
                        class="vs-row mb-3 mt-3"
                        v-if="getTronConnectionStatus === true"
                      >
                        <h6>
                          Connected Wallet :
                        </h6>
                        <p class="wallet-address">
                          {{ getDefaultAddress.base58 }}
                        </p>
                        <p class="wallet-address text-primary">
                          Available TRX Balance :
                          <span :class="trxValue < 20 && 'text-danger'">{{
                            trxValue
                          }}</span>
                        </p>
                      </div>

                      <div class="vs-row mb-3">
                        <h6>
                          Referral :
                        </h6>
                        <p class="wallet-address">
                          {{ referral }}
                        </p>
                      </div>
                    </div>

                    <img
                      :src="require(`@/assets/images/register.png`)"
                      alt="register"
                    />

                    <!-- USDT -->
                    <!-- <div class="vx-row mb-base mt-base">
                      <div class="vx-col w-full ">
                        <vs-input-number
                          :min="minAmountUSDT"
                          v-model="USDT.amount"
                          :disabled="USDT.inputDisable"
                          color="success"
                        />
                      </div>
                      <div class="vx-col w-full md:w-2/5">
                        <vs-button
                          class="w-full action"
                          @click="payUSDT"
                          v-if="USDT.paid === false"
                          :color="usdtHaveBalance ? 'success' : 'danger'"
                          :disabled="!usdtHaveBalance"
                        >
                          <p v-if="usdtHaveBalance">Approve USDT</p>
                          <p v-else>Not enough balance</p>
                          <p>Balance : {{ usdtBalance }}</p>
                        </vs-button>
                        <vs-button
                          disabled
                          v-else
                          class="w-full action"
                          color="success"
                          icon-pack="feather"
                          icon="icon-check"
                        ></vs-button>
                      </div>
                      <div class="vx-col w-full">
                        <table class="w-full">
                          <tbody>
                            <tr class="text-success">
                              <td class="w-1/3">
                                Min USDT : {{ contractInfo.minUsdtAmount }}
                              </td>
                              <td class="w-1/3 text-center">
                                USDT Fee : {{ contractInfo.usdtFeeAmount }}
                              </td>
                              <td class="w-1/3 text-right">
                                Total :
                                {{
                                  parseInt(contractInfo.minUsdtAmount) +
                                    parseInt(contractInfo.usdtFeeAmount)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> -->

                    <!-- ECS -->
                    <!-- <div class="vx-row mb-base">
                      <div class="vx-col w-full">
                        <vs-input-number
                          :min="minAmountECS"
                          v-model="ECS.amount"
                          :disabled="ECS.inputDisable"
                        />
                      </div>
                      <div class="vx-col w-full md:w-2/5">
                        <vs-button
                          class="w-full action"
                          @click="payECS"
                          v-if="ECS.paid === false"
                          :color="ecsHaveBalance ? 'primary' : 'danger'"
                          :disabled="!ecsHaveBalance"
                        >
                          <p v-if="ecsHaveBalance">Approve ECG</p>
                          <p v-else>Not enough balance</p>
                          <p>Balance : {{ ecsBalance }}</p>
                        </vs-button>
                        <vs-button
                          disabled
                          v-else
                          class="w-full action"
                          color="primary"
                          icon-pack="feather"
                          icon="icon-check"
                        ></vs-button>
                      </div>
                      <div class="vx-col w-full">
                        <table class="w-full">
                          <tbody>
                            <tr class="text-primary">
                              <td class="w-1/3">
                                Min ECG : {{ contractInfo.minEcsAmount }}
                              </td>
                              <td class="w-1/3 text-center">
                                ECG Fee : {{ contractInfo.ecsFeeAmount }}
                              </td>
                              <td class="w-1/3 text-right">
                                Total :
                                {{
                                  parseInt(contractInfo.minEcsAmount) +
                                    parseInt(contractInfo.ecsFeeAmount)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> -->

                    <div class="vx-row">
                      <div class="vx-col w-full register-btn">
                        <vs-button
                          id="register-loading"
                          class="w-full vs-con-loading__container"
                          :disabled="registerBtnDisabled"
                          @click="registerPrompt = true"
                        >
                          Register
                        </vs-button>
                      </div>

                      <vs-prompt
                        class="register-prompt"
                        title="Register"
                        accept-text="Confirm"
                        @accept="payAndRegister"
                        :active.sync="registerPrompt"
                      >
                        <div class="con-exemple-prompt">
                          <h6 class="text-danger inline-block">
                            IMPORTANT NOTE
                          </h6>
                          <span
                            >: You have been referred from this
                            <h6 class="inline-block break-all">
                              {{ referral }}
                            </h6>
                            wallet please confirm it</span
                          >
                        </div>
                      </vs-prompt>
                    </div>
                  </section>
                </template>

                <template v-else>
                  <div class="vx-row social-holder">
                    <div class="vx-col w-full text-center mb-10">
                      Have Not Link
                    </div>
                    <div class="vx-col w-full social">
                      <a
                        :href="item.link"
                        target="_blank"
                        v-for="(item, index) in socials"
                        :key="index"
                      >
                        <img
                          :src="
                            require(`@/assets/images/social/${item.name}.png`)
                          "
                          :alt="item.name"
                        />
                      </a>
                    </div>
                  </div>
                </template>
                <vs-divider color="primary" class="faq-divider"
                  ><router-link :to="{ name: 'register-faqs' }"
                    >FAQs</router-link
                  ></vs-divider
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { callContractMixin } from "@/mixins/callCotractMixin";
import TronWebComponent from "@/components/tron-web/TronWebComponent.vue";
export default {
  components: { TronWebComponent },
  data() {
    return {
      registerPrompt: false,

      haveLink: false,

      //
      interval: null,

      //
      trxValue: null,

      //
      referral: "",
      side: "",

      //
      ecsBalance: "",
      ecsHaveBalance: false,
      usdtBalance: "",
      usdtHaveBalance: false,

      //
      registerBtnDisabled: false,

      //
      tronWebInstallAddress:
        "https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec",

      //
      ECS: {
        amount: "",
        inputDisable: true,
        paid: true
      },

      //
      USDT: {
        amount: "",
        inputDisable: true,
        paid: true
      }
    };
  },

  computed: {
    ...mapGetters({
      getTronConnectionStatus: "tronBot/getTronConnectionStatus",
      getTronAccessStatus: "tronBot/getTronAccessStatus",
      getLoginStatus: "tronBot/getLoginStatus",
      getDefaultAddress: "tronBot/getDefaultAddress",
      getTronWebObject: "tronBot/getTronWebObject",
      //
      contractInfo: "contract/contractInfo",
      //
      socials: "auth/socials",
      //
      dashboard: "auth/dashboard",
      //
      user: "auth/user"
    }),

    minAmountUSDT() {
      let min = parseInt(this.contractInfo.minUsdtAmount);
      let fee = parseInt(this.contractInfo.usdtFeeAmount);
      let total = min + fee;
      return total;
    },

    minAmountECS() {
      let min = parseInt(this.contractInfo.minEcsAmount);
      let fee = parseInt(this.contractInfo.ecsFeeAmount);
      let total = min + fee;
      return total;
    }
  },

  methods: {
    // Vuex Map Actions
    ...mapActions({
      pay: "modulePayment/pay",
      getToken: "auth/getToken",
      getBaseInfo: "contract/getBaseInfo",
      getDashboardData: "auth/getDashboardData",
      checkUserExist: "auth/checkUserExist"
    }),
    //

    // Vuex Map Mutations
    ...mapMutations({
      setApprovedAmounts: "modulePayment/setApprovedAmounts"
    }),
    //

    // Redirect To Dashboard
    redirectLogin() {
      this.$vs.notify({
        title: "You Are Registered",
        text: "Redirecting To Login Page",
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right"
      });

      setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 2000);
    },
    //

    // Get Params From Query
    getQueryParams() {
      if (this.$route.fullPath === "/register") {
        this.haveLink = false;
      } else if (!this.$route.query) {
        this.haveLink = false;
      } else if (this.$route.query) {
        if (
          !this.$route.query.referral_code ||
          this.$route.query.referral_code === "undefined" ||
          !this.$route.query.side ||
          this.$route.query.side === "undefined"
        ) {
          this.haveLink = false;
        } else {
          this.haveLink = true;
          this.referral = this.$route.query.referral_code;
          this.side = this.$route.query.side;

          // check referral wallet address
          const walletCheck = /^T[a-zA-Z0-9]{33}$/.test(this.referral);
          if (!walletCheck) {
            this.$vs.notify({
              title: "Referral",
              text: "Your referral address does not work in this version",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right"
            });

            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 2000);
          }
        }
      }
    },
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.getDefaultAddress.base58],
        res => {
          if (res) {
            let ecsBalance = parseInt(res._hex);
            this.ecsBalance = this.getTronWebObject.fromSun(ecsBalance);
          }
        },
        false
      );
    },
    //

    // Get USDT Balance Of
    async getUSDTBalanceOf() {
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "balanceOf",
        [this.getDefaultAddress.base58],
        res => {
          if (res) {
            let usdtBalance = parseInt(res._hex);
            this.usdtBalance = this.getTronWebObject.fromSun(usdtBalance);
          }
        },
        false
      );
    },
    //

    // Pay USDT
    async payUSDT() {
      let usdt = this.getTronWebObject.toSun(this.USDT.amount);
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "allowance",
        [this.getDefaultAddress.base58, this.contractInfo.contractAddress],
        res => {
          if (res) {
            if (res.remaining >= +usdt) {
              this.USDT.inputDisable = true;
              this.USDT.paid = true;

              if (this.ECS.paid === true) {
                this.registerBtnDisabled = false;
              }
            } else {
              this.callContract(
                this.contractInfo.usdtContractAddress,
                "approve",
                [this.contractInfo.contractAddress, usdt],
                res => {
                  if (res) {
                    this.USDT.inputDisable = true;
                    this.USDT.paid = true;

                    if (this.ECS.paid === true) {
                      this.registerBtnDisabled = false;
                    }
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    // Pay ECS
    async payECS() {
      let ecs = this.getTronWebObject.toSun(this.ECS.amount);
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "allowance",
        [this.getDefaultAddress.base58, this.contractInfo.contractAddress],
        res => {
          if (res) {
            if (res >= +ecs) {
              this.ECS.inputDisable = true;
              this.ECS.paid = true;

              //
              if (this.USDT.paid === true) {
                this.registerBtnDisabled = false;
              }
            } else {
              this.callContract(
                this.contractInfo.ecsContractAddress,
                "approve",
                [this.contractInfo.contractAddress, ecs],
                res => {
                  if (res) {
                    this.ECS.inputDisable = true;
                    this.ECS.paid = true;

                    if (this.USDT.paid === true) {
                      this.registerBtnDisabled = false;
                    }
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    // Pay And Register New User
    async payAndRegister() {
      let referral = this.referral;
      let side = this.side === "left" ? false : true;

      //
      await this.callContract(
        this.contractInfo.contractAddress,
        "register",
        [referral, side],
        res => {
          if (res) {
            this.pursueTransaction(res);

            this.setApprovedAmounts({
              ECS: this.ECS.amount,
              USDT: this.USDT.amount
            });

            this.startUserExistInterval();
          }
        },
        true
      );
    },
    //

    // Pursue Transaction
    pursueTransaction(hash) {
      this.$vs.notify({
        title: "Persure Transaction",
        text: "Click Here To Persure Transaction",
        color: "primary",
        click: () => {
          window.open(`https://tronscan.org/#/transaction/${hash}`, "_blank");
        }
      });
    },
    //

    // User Exist Interval
    async startUserExistInterval() {
      this.registerBtnDisabled = true;
      this.$vs.loading({
        container: "#register-loading",
        scale: 0.45
      });
      await this.check_user_exist();
      this.interval = setInterval(() => {
        this.check_user_exist();
      }, 5000);
    },
    //

    // Stop User Exist Interval
    stopUserExistInterval() {
      this.registerBtnDisabled = false;
      this.$vs.loading.close("#register-loading > .con-vs-loading");
      clearInterval(this.interval);
    },
    //

    // Check User Exist
    async check_user_exist() {
      await this.checkUserExist(this.getDefaultAddress.base58).then(res => {
        if (res) {
          this.stopUserExistInterval();
          this.redirectLogin();
        }
      });
    },
    //

    // get trx value
    async getTrxValue() {
      const res = await this.getTronWebObject.trx.getBalance(
        this.getDefaultAddress.base58
      );
      this.trxValue = this.getTronWebObject.fromSun(res);
    },

    // Get USDT & ECS Balance
    async getBalance() {
      if (this.getTronWebObject && this.contractInfo.contractAddress) {
        await this.getECSBalanceOf();
        await this.getUSDTBalanceOf();
        // Get TRX Balance
        await this.getTrxValue();
        //

        // Set Min Inevesments For ECS & USDT
        let minUSDT = parseInt(this.contractInfo.minUsdtAmount);
        let feeUSDT = parseInt(this.contractInfo.usdtFeeAmount);
        this.USDT.amount = minUSDT + feeUSDT;

        let minECS = parseInt(this.contractInfo.minEcsAmount);
        let feeECS = parseInt(this.contractInfo.ecsFeeAmount);
        this.ECS.amount = minECS + feeECS;
        //
      }
    }
    //
  },
  async mounted() {
    // Get Params From Query
    this.getQueryParams();
    //

    // Get Base Info
    await this.getBaseInfo();
    //

    // Get USDT & ECS Balance
    setTimeout(() => {
      this.getBalance();
    }, 2000);
    //
  },

  beforeDestroy() {
    this.stopUserExistInterval();
  },
  watch: {
    // Get USDT & ECS Balance
    getTronWebObject() {
      this.getBaseInfo().then(res => {
        if (res === true) {
          this.getBalance();

          this.callContract(
            this.contractInfo.contractAddress,
            "getToken",
            [],
            res => {
              if (res) {
                this.$router.push("/login");
              }
            },
            false
          );
        }
      });
    },
    //

    $route() {
      window.location.reload();
    }
  },
  mixins: [callContractMixin]
};
</script>

<style lang="scss">
@import "./register.scss";
</style>
